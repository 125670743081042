import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { mergeMap, Observable } from "rxjs";
import { MsalService } from "@azure/msal-angular";
import { environment } from "../../../environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor, OnInit {


  constructor(private authService: MsalService) {
    console.log('init token component')
  }
  

  ngOnInit(): void {
    console.log('clearing everything prior to call');
    localStorage.clear();
    sessionStorage.clear();
  }

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.indexOf(environment.msGraphUrl) > -1) {
      return this.addAuthHeaderForGraph(request, next);
    } else if (request.url.indexOf(environment.apiUrl) > -1) {
      return this.addAuthHeaderForApi(request, next);
    } else if (request.url.indexOf(environment.athenaApiUrl) > -1) {
      return this.addAuthHeaderForSearch(request, next);
    }
  }

  private addAuthHeaderForGraph(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const loginRequest = {
      scopes: environment.msalConfig.graphScopes,
    };
    console.log('hitting graph auth');
    return this.authService.acquireTokenSilent(loginRequest).pipe(
      mergeMap((token) => {
        return next.handle(
          request.clone({
            setHeaders: {
              Authorization: `Bearer ${token.accessToken}`,
            },
          })
        );
      })
    );
  }


  private addAuthHeaderForApi(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const loginRequest = {
      scopes: environment.msalConfig.oidcScopes,
    };
    console.log('hitting api auth');
    return this.authService.acquireTokenSilent(loginRequest).pipe(
      mergeMap((token) => {
        return next.handle(
          request.clone({
            setHeaders: {
              Authorization: `Bearer ${token.idToken}`,
            },
          })
        );
      })
    );
  }

  private addAuthHeaderForSearch(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const loginRequest = {
      scopes: environment.msalConfig.athenaSearchScope,
    };
    console.log('hitting search auth');
    return this.authService.acquireTokenSilent(loginRequest).pipe(
      mergeMap((token) => {
        return next.handle(
          request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          })
        );
      })
    );
  }

}
