import { environment } from "src/environments/environment";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from "@azure/msal-angular";

export function loggerCallback(logLevel: LogLevel, message: string) {}

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  console.log('creating msal instance')
  return new PublicClientApplication({
    auth: environment.msalConfig.browserAuth,
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  console.log('msal interceptor')
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(`${environment.apiUrl}/*`, [
    `${environment.appRegistration.clientId}/user_impersonation`,
  ]);
  protectedResourceMap.set(
    "https://graph.microsoft.com/v1.0/me",
    environment.msalConfig.graphScopes
  );
  protectedResourceMap.set(environment.athenaApiUrl, [
    `${environment.msalConfig.athenaSearchScope}`,
  ]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  console.log('login failed redirect');

  //
  // localStorage.clear();
  // sessionStorage.clear();



  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["user.read"],
    },
    loginFailedRoute: "/login-failed",
  };
}
