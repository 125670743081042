import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { ErrorDialogService } from "src/app/shared/components/error-dialog/error-dialog.service";
import { ToastService } from "../services/toast.service";

@Injectable()
export class LessonErrorHandler implements ErrorHandler {
    private urlsToIgnore: string[] = [
        "https://graph.microsoft.com/v1.0/me/photo/$value" // 404 properly returned when user picture is missing
    ];

    constructor(
        private errorDialogService: ErrorDialogService,
        private zone: NgZone,
        private toastService: ToastService
    ) { }

    //temporarily leave spaghetti cases for debugging

    handleError(err: any): void {
      console.log('error verbosity: ', err);
      console.log('error verbosity msg: ', err.message);
      if(err.message.includes('hash_empty_error')){ //if it works 
        console.log('hit err msg reset (hash_empty_error)');
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        console.log('clearing storages')
      }
      else if(err.message.includes('/api/User/loggedUser')){
        console.log('hit err msg reset (logged user)');
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        console.log('clearing storages')
      }
      else if(err.message.includes('/oath2/v2.0/token')){
        console.log('hit err msg reset (/oath2/v2.0/token)');
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        console.log('clearing storages')
      }
      else if(err.message.includes('no_tokens_found')){
        console.log('hit err msg reset (no_tokens_found)');
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        console.log('clearing storages')
      }
      else if(err.message.includes('monitor_window_timeout')){
        console.log('hit err msg reset (monitor_window_timeout)');
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        console.log('clearing storages')
      }
    }
}
